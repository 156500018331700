import React, { useState, useEffect } from 'react';
import { Editor, EditorState, RichUtils, Modifier, convertFromRaw, ContentState, SelectionState } from 'draft-js';
import { db } from '../firebaseConfig';
import { collection, getDoc, serverTimestamp, getDocs, query, where, orderBy, updateDoc, doc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import 'draft-js/dist/Draft.css';
import './QuizManagement.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getFirestore, addDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
const QuizManagement = () => {
  const location = useLocation();

  const [categories, setCategories] = useState([]);
  const [selected1stCategory, setSelected1stCategory] = useState('');
  const [selected2ndCategory, setSelected2ndCategory] = useState('');
  const [selected3rdCategory, setSelected3rdCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [quizType, setQuizType] = useState('a');
  const [questionEditorState, setQuestionEditorState] = useState(EditorState.createEmpty());
  const [explanationEditorState, setExplanationEditorState] = useState(EditorState.createEmpty());
  const [savedQuestion, setSavedQuestion] = useState('');
  const [answer, setAnswer] = useState('true');
  const [comparison, setComparison] = useState('');
  const [tag, setTag] = useState('');
  const [premise, setPremise] = useState('');
  const [options, setOptions] = useState(['', '', '', '', '']);
  const [dTypeAnswers, setDTypeAnswers] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [num, setNum] = useState('');
  const [errors, setErrors] = useState({});
  const [comparisonEditorState, setComparisonEditorState] = useState(EditorState.createEmpty());
  const [selected1stCategoryName, setSelected1stCategoryName] = useState('');
  const [selected2ndCategoryName, setSelected2ndCategoryName] = useState('');
  const [selected3rdCategoryName, setSelected3rdCategoryName] = useState('');
  const { quizId } = useParams();
  const { quiz } = location.state || {};
  const isEditing = location.state?.isEditing || false;
  const [questionUnderlines, setQuestionUnderlines] = useState([]);
  const [explanationUnderlines, setExplanationUnderlines] = useState([]);
  const [comparisonUnderlines, setComparisonUnderlines] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    if (selected1stCategory) {
      fetchSubCategories(selected1stCategory, selected1stCategoryName);
    }
  }, [selected1stCategory]);

  useEffect(() => {
    if (selected2ndCategory) {
      fetchSubSubCategories(selected2ndCategory, selected2ndCategoryName);
    }
  }, [selected2ndCategory]);

  useEffect(() => {
    console.log('useEffect: 수정 모드 데이터 로딩', { isEditing, quiz, categories });
    if (isEditing && quiz && categories.length > 0) {
      console.log('수정 모드 데이터 로딩 시작');
      populateFormForEditing(quiz);
    }
  }, [isEditing, quiz, categories]);

  const fetchCategories = async () => {
    console.log('fetchCategories 시작');
    const querySnapshot = await getDocs(query(collection(db, '1stCategories'), orderBy('createdAt', 'asc')));
    const categoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    console.log('fetchCategories 결과', categoriesData);
    setCategories(categoriesData);
  };

  const fetchSubCategories = async (categoryId) => {
    const q = query(collection(db, `1stCategories/${categoryId}/2ndCategories`), orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subCategoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setSubCategories(subCategoriesData);
  };

  const fetchSubSubCategories = async (categoryId) => {
    if (!selected1stCategory || !categoryId) return;
    const q = query(collection(db, `1stCategories/${selected1stCategory}/2ndCategories/${categoryId}/3rdCategories`), orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subSubCategoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setSubSubCategories(subSubCategoriesData);
  };

  const validateQuiz = () => {
    let isValid = true;
    let newErrors = {};

    if (!selected1stCategory) {
      isValid = false;
      newErrors.selected1stCategory = '카테고리를 선택해주세요';
    }
    if (!selected2ndCategory) {
      isValid = false;
      newErrors.selected2ndCategory = '카테고리를 선택해주세요';
    }

    if (!quizType) {
      isValid = false;
      newErrors.quizType = '문제 유형을 선택해주세요';
    }
    if (!questionEditorState.getCurrentContent().hasText()) {
      isValid = false;
      newErrors.question = '문제를 입력해주세요';
    }
    if (!explanationEditorState.getCurrentContent().hasText()) {
      isValid = false;
      newErrors.explanation = '설명을 입력해주세요';
    }
    if (!tag) {
      isValid = false;
      newErrors.tag = '태그를 입력해주세요';
    }

    setErrors(newErrors);
    console.log('validateQuiz 결과', isValid);
    return isValid;
  };



  const setBlank = () => {
    const selectionState = questionEditorState.getSelection();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = questionEditorState.getCurrentContent().getPlainText().slice(start, end);

    if (start !== end) {
      const newDTypeAnswer = { start, end, text: selectedText };
      setDTypeAnswers([...dTypeAnswers, newDTypeAnswer]);

      const contentState = Modifier.replaceText(
        questionEditorState.getCurrentContent(),
        selectionState,
        '_'.repeat(end - start)
      );

      const newEditorState = EditorState.push(questionEditorState, contentState, 'insert-characters');
      setQuestionEditorState(newEditorState);
    }
  };
  const createEditorState = (content, underlines) => {
    let contentState = ContentState.createFromText(content);
    let editorState = EditorState.createWithContent(contentState);

    if (underlines && underlines.length > 0) {
      underlines.forEach(underline => {
        const blockMap = contentState.getBlockMap();
        const block = blockMap.get(underline.blockKey);

        if (block) {
          const selection = SelectionState.createEmpty(underline.blockKey).merge({
            anchorOffset: underline.start,
            focusOffset: underline.end,
          });
          contentState = Modifier.applyInlineStyle(contentState, selection, 'UNDERLINE');
          editorState = EditorState.push(editorState, contentState, 'change-inline-style');
        }
      });
    }

    return editorState;
  };

  const populateFormForEditing = async (quiz) => {
    console.log('populateFormForEditing 시작', quiz);
    const findCategory = async (categoryId, level = 1) => {
      if (level > 3) return null;
    
      let categoryRef;
      if (level === 1) {
        categoryRef = doc(db, '1stCategories', categoryId);
      } else if (level === 2 && selected1stCategory) {
        categoryRef = doc(db, `1stCategories/${selected1stCategory}/2ndCategories`, categoryId);
      } else if (level === 3 && selected1stCategory && selected2ndCategory) {
        categoryRef = doc(db, `1stCategories/${selected1stCategory}/2ndCategories/${selected2ndCategory}/3rdCategories`, categoryId);
      } else {
        return findCategory(categoryId, level + 1);
      }
    
      const categoryDoc = await getDoc(categoryRef);
      if (categoryDoc.exists()) {
        return { id: categoryDoc.id, ...categoryDoc.data(), level };
      }
    
      return findCategory(categoryId, level + 1);
    };
    

    const category = await findCategory(quiz.category);

    if (category) {
      if (category.level >= 1) {
        setSelected1stCategory(category.level === 1 ? category.id : category.parentId);
        await fetchSubCategories(category.level === 1 ? category.id : category.parentId);
      }
      if (category.level >= 2) {
        setSelected2ndCategory(category.level === 2 ? category.id : category.parentId);
        await fetchSubSubCategories(category.level === 2 ? category.id : category.parentId);
      }
      if (category.level === 3) {
        setSelected3rdCategory(category.id);
      }
    }
    if (quiz.type === 'a' || quiz.type === 'b') {
      setAnswer(quiz.answer.toString()); // 'true' 또는 'false'로 변환
    } else if (quiz.type === 'c') {
      setAnswer(quiz.answer.toString()); // 선택된 옵션 번호로 변환
    } else if (quiz.type === 'd') {
      setDTypeAnswers(quiz.answer || []); // D 타입 답변 설정
    }

    setQuizType(quiz.type);
    setQuestionEditorState(createEditorState(quiz.question, quiz.underlineSelections?.question));
    setPremise(quiz.premise);
    setComparisonEditorState(createEditorState(quiz.comparison, quiz.underlineSelections?.comparison));
    setAnswer(quiz.answer);
    setTag(quiz.tag);
    setPremise(quiz.premise || '');
    setOptions(quiz.options || ['', '', '', '', '']);
    setDTypeAnswers(quiz.dTypeAnswers || []);
    setNum(quiz.num.toString());
    setExplanationEditorState(createEditorState(quiz.explanation, quiz.underlineSelections?.explanation));
    if (quiz.underlineSelections) {
      setQuestionUnderlines(quiz.underlineSelections.question || []);
      setExplanationUnderlines(quiz.underlineSelections.explanation || []);
      setComparisonUnderlines(quiz.underlineSelections.comparison || []);
    }
  };

  
  useEffect(() => {
    if (selected1stCategory) {
      fetchSubCategories(selected1stCategory);
    }
  }, [selected1stCategory]);
  
  useEffect(() => {
    if (selected2ndCategory) {
      fetchSubSubCategories(selected2ndCategory);
    }
  }, [selected2ndCategory]);
const handleAddQuiz = async () => {
  if (!validateQuiz()) {
    return;
  }

  let quizNum = num ? parseInt(num) : null;

  const quizzesRef = collection(db, 'quizzes');
  const q = query(quizzesRef, where('category', '==', selected3rdCategory || selected2ndCategory || selected1stCategory), orderBy('num', 'asc'));
  const querySnapshot = await getDocs(q);
  const existingQuizzes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  const categoryData = selected3rdCategory || selected2ndCategory || selected1stCategory || 'default';

  if (!quizNum) {
    quizNum = existingQuizzes.length > 0 ? existingQuizzes[existingQuizzes.length - 1].num + 1 : 1;
  } else {
    const conflictingQuizzes = existingQuizzes.filter(quiz => quiz.num >= quizNum);
    for (let quiz of conflictingQuizzes) {
      await updateDoc(doc(db, 'quizzes', quiz.id), { num: quiz.num + 1 });
    }
  }

  let categoryPath = selected1stCategoryName;
  if (selected2ndCategoryName) {
    categoryPath += ` - ${selected2ndCategoryName}`;
  }
  if (selected3rdCategoryName) {
    categoryPath += ` - ${selected3rdCategoryName}`;
  }

  let quizData = {
    question: questionEditorState.getCurrentContent().getPlainText(),
    answer: quizType === 'a' || quizType === 'b' ? answer === true : answer,
    explanation: explanationEditorState.getCurrentContent().getPlainText(),
    comparison: comparisonEditorState.getCurrentContent().getPlainText(),
    tag,
    createdAt: serverTimestamp(),
    createdBy: user?.uid,
    category: categoryData,
    type: quizType,
    num: quizNum,
    underlineSelections: {
      question: getUnderlines(questionEditorState),
      explanation: getUnderlines(explanationEditorState)
    },
    categoryPath
  };

  if (quizType === 'b') {
    quizData.premise = premise;
  } else if (quizType === 'c') {
    quizData.options = options;
  } else if (quizType === 'd') {
    quizData.answer = dTypeAnswers;
  }

  let quizId;
  if (isEditing && quiz) {
    await updateDoc(doc(db, 'quizzes', quiz.id), quizData);
    quizId = quiz.id;
    alert('문제가 성공적으로 수정되었습니다!');
  } else {
    const docRef = await addDoc(collection(db, 'quizzes'), quizData);
    quizId = docRef.id;
    alert('문제가 성공적으로 추가되었습니다!');
  }

  // Prepare embedding text
  const embeddingText = `
    Question: ${quizData.question}
    Answer: ${quizData.answer}
    Explanation: ${quizData.explanation}
    Comparison: ${quizData.comparison}
    Tag: ${quizData.tag}
    Category: ${quizData.categoryPath}
  `;

  // Call the createEmbeddingAndStore function
  console.log('Attempting to create embedding...');
  try {
    const functions = getFunctions(getApp());
    const createEmbeddingAndStore = httpsCallable(functions, 'createEmbeddingAndStore');
    console.log('Calling createEmbeddingAndStore function...');
    const result = await createEmbeddingAndStore({
      uid: quizId,
      embeddingText: embeddingText
    });
    console.log('Function call completed. Result:', result);
  
    if (result.data.success) {
      console.log('Embedding created and stored successfully');
    } else {
      console.error('Failed to create and store embedding');
    }
  } catch (error) {
    console.error('Error calling createEmbeddingAndStore:', error);
  }
  
  // Reset form fields
  setQuestionEditorState(EditorState.createEmpty());
  setSavedQuestion('');
  setAnswer();
  setTag('');
  setOptions(['', '', '', '', '']);
  setDTypeAnswers([]);
  setNum('');

  if (isEditing) {
    navigate(-1);
  }
};
  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleDTypeAnswerChange = (index, field, value) => {
    const newDTypeAnswers = [...dTypeAnswers];
    newDTypeAnswers[index] = { ...newDTypeAnswers[index], [field]: value };
    setDTypeAnswers(newDTypeAnswers);
  };

  const handleEditorChange = (editorState, setEditorState) => {
    setEditorState(editorState);
  };

  const toggleUnderline = (editorState, setEditorState) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const getUnderlines = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    let underlines = [];
    let currentUnderline = null;
    let absoluteOffset = 0;

    blocks.forEach((block, blockIndex) => {
      const text = block.getText();
      const characterList = block.getCharacterList();

      characterList.forEach((char, index) => {
        if (char.hasStyle('UNDERLINE')) {
          if (!currentUnderline) {
            currentUnderline = { start: absoluteOffset + index, text: text[index] };
          } else {
            currentUnderline.text += text[index];
          }
        } else if (currentUnderline) {
          currentUnderline.end = absoluteOffset + index;
          underlines.push(currentUnderline);
          currentUnderline = null;
        }
      });

      if (currentUnderline) {
        currentUnderline.end = absoluteOffset + text.length;
        underlines.push(currentUnderline);
        currentUnderline = null;
      }

      absoluteOffset += text.length + 1; // +1 for the newline character
    });

    return underlines;
  };
  const renderTextWithUnderlines = (text, underlines) => {
    let result = '';
    let lastIndex = 0;
    let currentBlockLength = 0;
  
    underlines.forEach(({ start, end, text: underlineText }) => {
      while (lastIndex < start) {
        if (text[lastIndex] === '\n') {
          result += '<br>';
          currentBlockLength = 0;
        } else {
          result += text[lastIndex];
          currentBlockLength++;
        }
        lastIndex++;
      }
      result += `<u>${underlineText}</u>`;
      lastIndex = end;
      currentBlockLength += underlineText.length;
    });
  
    while (lastIndex < text.length) {
      if (text[lastIndex] === '\n') {
        result += '<br>';
        currentBlockLength = 0;
      } else {
        result += text[lastIndex];
        currentBlockLength++;
      }
      lastIndex++;
    }
  
    return result;
  };
  

  return (
    <div className="quiz-management">
      <h1>{isEditing ? '문제 수정' : ''}</h1>
      {isEditing &&(<h3>카테고리 다시 반영해야함 || 밑줄 다시 설정해야함</h3>)}
      <div className="form-group">
        <label>1차 카테고리 선택</label>
        <select
          value={selected1stCategory}
          onChange={(e) => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            setSelected1stCategory(e.target.value);
            setSelected1stCategoryName(selectedOption.text);
          }}
          className={errors.selected1stCategory ? 'error' : ''}
        >
          <option value="">카테고리 선택</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
        {errors.selected1stCategory && <span className="error-message">{errors.selected1stCategory}</span>}
      </div>
      {selected1stCategory && subCategories.length > 0 && (
        <div className="form-group">
          <label>2차 카테고리 선택</label>
          <select
            value={selected2ndCategory}
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              setSelected2ndCategory(e.target.value);
              setSelected2ndCategoryName(selectedOption.text);
            }}
            className={errors.selected2ndCategory ? 'error' : ''}
          >
            <option value="">카테고리 선택</option>
            {subCategories.map((category) => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
          {errors.selected2ndCategory && <span className="error-message">{errors.selected2ndCategory}</span>}
        </div>
      )}
      {selected2ndCategory && subSubCategories.length > 0 && (
        <div className="form-group">
          <label>3차 카테고리 선택</label>
          <select
            value={selected3rdCategory}
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              setSelected3rdCategory(e.target.value);
              setSelected3rdCategoryName(selectedOption.text);
            }}
            className={errors.selected3rdCategory ? 'error' : ''}
          >
            <option value="">카테고리 선택</option>
            {subSubCategories.map((category) => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
          {errors.selected3rdCategory && <span className="error-message">{errors.selected3rdCategory}</span>}
        </div>
      )}
      {selected2ndCategory && subSubCategories.length === 0 && (
        <div className="form-group">
          <label>하위 카테고리 없음</label>
        </div>
      )}
      <div className="form-group">
        <label>문제 유형 선택</label>
        <select
          value={quizType}
          onChange={(e) => setQuizType(e.target.value)}
          className={errors.quizType ? 'error' : ''}
        >
          <option value="a">Type A (True/False)</option>
          <option value="b">Type B (Premise + True/False)</option>
          <option value="c">Type C (Multiple Choice)</option>
          <option value="d">Type D (Fill in the Blank)</option>
        </select>
        {errors.quizType && <span className="error-message">{errors.quizType}</span>}
      </div>
      {quizType === 'b' && (
        <div className="form-group">
          <label>기본 지문</label>
          <textarea
            id="premise"
            value={premise}
            onChange={(e) => setPremise(e.target.value)}
          />
        </div>
      )}
      <div className="form-group">
        <label>문제</label>
        <div className="editor-container">
          <Editor
            editorState={questionEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setQuestionEditorState)}
          />
          {quizType === 'd' && (
            <button onClick={setBlank}>빈칸설정</button>
          )}
          <button onClick={() => toggleUnderline(questionEditorState, setQuestionEditorState)}>밑줄 설정</button>
        </div>
        {errors.question && <span className="error-message">{errors.question}</span>}
      </div>
      {quizType === 'd' && (
        <div className="form-group">
          <label>미리보기</label>
          <p>{questionEditorState.getCurrentContent().getPlainText()}</p>
        </div>
      )}
      {quizType === 'c' && (
        <div className="form-group">
          <label>보기</label>
          {options.map((option, index) => (
            <input
              key={index}
              type="text"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              placeholder={`Option ${index + 1}`}
            />
          ))}
        </div>
      )}
      {quizType === 'd' && (
        <div className="form-group">
          <label>정답 (빈칸 채우기)</label>
          {dTypeAnswers.map((answer, index) => (
            <div key={index} className="d-type-answer">
              <input
                type="text"
                value={answer.start}
                onChange={(e) => handleDTypeAnswerChange(index, 'start', e.target.value)}
                placeholder="Start"
                readOnly
              />
              <input
                type="text"
                value={answer.end}
                onChange={(e) => handleDTypeAnswerChange(index, 'end', e.target.value)}
                placeholder="End"
                readOnly
              />
              <input
                type="text"
                value={answer.text}
                onChange={(e) => handleDTypeAnswerChange(index, 'text', e.target.value)}
                placeholder="Text"
                readOnly
              />
            </div>
          ))}
        </div>
      )}
<div className="form-group">
  {quizType === 'a' || quizType === 'b' ? (
    <div className="true-false-buttons">
      <button
        className={`true-false-button ${answer === true ? 'selected' : ''}`}
        onClick={() => setAnswer(true)}
      >
        O
      </button>
      <button
        className={`true-false-button ${answer === false ? 'selected' : ''}`}
        onClick={() => setAnswer(false)}
      >
        X
      </button>
    </div>
  ) : quizType === 'c' ? (
    <select value={answer} onChange={(e) => setAnswer(e.target.value)}>
      <option value="">정답 선택</option>
      {options.map((_, index) => (
        <option key={index} value={(index + 1).toString()}>{index + 1}</option>
      ))}
    </select>
  ) : null}
</div>

      <div className="form-group">
        <label>설명</label>
        <div className="editor-container">
          <Editor
            editorState={explanationEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setExplanationEditorState)}
          />
          <button onClick={() => toggleUnderline(explanationEditorState, setExplanationEditorState)}>밑줄 설정</button>
        </div>
        {errors.explanation && <span className="error-message">{errors.explanation}</span>}
      </div>

      <div className="form-group">
        <label>비교 (선택사항)</label>
        <div className="editor-container">
          <Editor
            editorState={comparisonEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setComparisonEditorState)}
          />
          <button onClick={() => toggleUnderline(comparisonEditorState, setComparisonEditorState)}>밑줄 설정</button>
        </div>
      </div>

      <div className="form-group">
        <label>태그 (선택사항)</label>
        <input
          type="text"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          className={errors.tag ? 'error' : ''}
        />
        {errors.tag && <span className="error-message">{errors.tag}</span>}
      </div>
      <div className="form-group">
        <label>문제 번호 (선택사항)</label>
        <input
          type="number"
          value={num}
          onChange={(e) => setNum(e.target.value)}
          placeholder="자동 생성됩니다"
        />
      </div>
      <button onClick={handleAddQuiz}>{isEditing ? '문제 수정' : '문제 추가'}</button>
    </div>
  );
};

export default QuizManagement;
