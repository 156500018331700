import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import './ColorPicker.css';
import { HexColorPicker } from 'react-colorful';

const ColorPicker = ({ onColorSelect }) => {
  const [colors, setColors] = useState(['#fff515d9', '#ffc0cba6', '#66c4ffa3']);
  const [showCustomPicker, setShowCustomPicker] = useState(false);
  const [customColor, setCustomColor] = useState('#000000');
  const colorPickerRef = useRef(null);

  useEffect(() => {
    fetchUserColors();
    document.addEventListener('pointerdown', handleOutsideClick, { passive: true });
    return () => {
      document.removeEventListener('pointerdown', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
      setShowCustomPicker(false);
    }
  };

  const fetchUserColors = async () => {
    const user = auth.currentUser;
    if (!user) return;
    try {
      const userSettingsRef = doc(db, 'users_settings', user.uid);
      const userSettingsSnap = await getDoc(userSettingsRef);
      if (userSettingsSnap.exists() && userSettingsSnap.data().colorPalette) {
        setColors(userSettingsSnap.data().colorPalette);
      }
    } catch (error) {
      console.error('Error fetching user colors:', error);
    }
  };

  const handleCustomColorSelect = async () => {
    const user = auth.currentUser;
    if (!user) return;
    const newColor = customColor;
    setColors(prevColors => {
      const newColors = [...prevColors, newColor];
      saveUserColors(newColors);
      return newColors;
    });
    setShowCustomPicker(false);
  };

  const saveUserColors = async (newColors) => {
    const user = auth.currentUser;
    if (!user) return;
    try {
      const userSettingsRef = doc(db, 'users_settings', user.uid);
      await setDoc(userSettingsRef, { colorPalette: newColors }, { merge: true });
    } catch (error) {
      console.error('Error saving user colors:', error);
    }
  };

  const removeColor = async (indexToRemove) => {
    const user = auth.currentUser;
    if (!user) return;
    setColors(prevColors => {
      const newColors = prevColors.filter((_, index) => index !== indexToRemove);
      saveUserColors(newColors);
      return newColors;
    });
  };

  const handleInteraction = (callback) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    callback();
  };

  return (
    <div className="color-picker__container" ref={colorPickerRef} style={{ touchAction: 'manipulation' }}>
      {colors.map((color, index) => (
        <div key={index} className="color-picker__color-wrapper">
          <button
            className="color-picker__button"
            style={{ backgroundColor: color }}
            onPointerDown={handleInteraction(() => onColorSelect(color))}
          />
          {index >= 3 && (
            <button
              className="color-picker__remove-button"
              onPointerDown={handleInteraction(() => removeColor(index))}
            >
              ×
            </button>
          )}
        </div>
      ))}
      <button 
        className="color-picker__add-button"
        onPointerDown={handleInteraction(() => setShowCustomPicker(!showCustomPicker))}
      >
        +
      </button>
      
{showCustomPicker && (
  <div className="color-picker__custom-picker">
    <HexColorPicker
      color={customColor}
      onChange={setCustomColor}
    />
    <button 
      onPointerDown={handleInteraction(handleCustomColorSelect)}
      className="color-picker__add-color-button"
    >
      추가
    </button>
  </div>
)}
    </div>
  );
};

export default ColorPicker;
