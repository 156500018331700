// src/components/Login.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../store';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider, db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { serverTimestamp } from 'firebase/firestore';

import './Login.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      console.log("Saving user data:", user);

      // 사용자 정보 저장 또는 업데이트
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        displayName: user.displayName,
        lastSignInTime: serverTimestamp(),
        creationTime: user.metadata.creationTime,
        role: "user", // 기본 역할 설정
        isActive: true, // 로그인 시 활성화 상태를 true로 설정
      }, { merge: true });

      console.log("User data saved successfully");

      dispatch(setUser(user));
      navigate('/'); // 로그인 성공 후 홈페이지로 리다이렉트
    } catch (error) {
      console.error('Error during login:', error);
      alert('로그인에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  return (
    <div className="login-container">
      <button onClick={handleLogin} className="login-button">
        <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />
        구글로 로그인
      </button>
    </div>
  );
};

export default Login;
