import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import './UserManagement.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [sortField, setSortField] = useState('lastSignInTime');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filter, setFilter] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const usersCollection = collection(db, 'users');
    const userSnapshot = await getDocs(usersCollection);
    const userList = userSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setUsers(userList);
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleFilter = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  const toggleUserStatus = async (userId, currentStatus) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      isActive: !currentStatus
    });
    fetchUsers();
  };

  const filteredUsers = users.filter(user =>
    (user.email?.toLowerCase().includes(filter) || '') ||
    (user.role?.toLowerCase().includes(filter) || '')
  );

  const sortedUsers = filteredUsers.sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  return (
    <div className="user-management">
      <input
        type="text"
        placeholder="이메일 또는 역할로 필터링"
        onChange={handleFilter}
        className="filter-input"
      />
      <table className="user-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('email')}>이메일</th>
            <th onClick={() => handleSort('displayName')}>이름</th>
            <th onClick={() => handleSort('role')}>역할</th>
            <th onClick={() => handleSort('lastSignInTime')}>마지막 접속일</th>
            <th onClick={() => handleSort('creationTime')}>가입일</th>
            <th>작업</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map(user => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.displayName}</td>
              <td>{user.role}</td>
              <td>{new Date(user.lastSignInTime).toLocaleString()}</td>
              <td>{new Date(user.creationTime).toLocaleString()}</td>
              <td>
                <button onClick={() => toggleUserStatus(user.id, user.isActive)}>
                  {user.isActive ? '비활성화' : '활성화'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;
