import React, { useState } from 'react';
import './Admin.css';

import CategoryManagement from './CategoryManagement';
import QuizManagement from './QuizManagement';
import QuizList from './QuizList';
import UserManagement from './UserManagement';
import NoticeManagement from './NoticeManagement';
import FillCategoryPath from './FillCategoryPath';
import FillEmbeddings from './FillEmbeddings'; // New component

const Admin = () => {
  const [activeMenu, setActiveMenu] = useState('');

  const menuItems = [
    { name: '카테고리 관리', icon: '📁', component: CategoryManagement },
    { name: '문제 추가', icon: '➕', component: QuizManagement },
    { name: '문제 리스트', icon: '📝', component: QuizList },
    { name: '유저 관리', icon: '👥', component: UserManagement },
    { name: '공지사항 관리', icon: '📢', component: NoticeManagement },
    { name: '카테고리명 채우기 (누르지 말 것)', icon: '🔧', component: FillCategoryPath },
    { name: '임베딩 채우기', icon: '🧠', component: FillEmbeddings }, // New menu item

  ];

  const renderComponent = () => {
    const ActiveComponent = menuItems.find(item => item.name === activeMenu)?.component;
    return ActiveComponent ? <ActiveComponent /> : null;
  };

  return (
    <div className="admin-container">
      <div className="admin-sidebar">
        <div className="admin-header">
          <h2 className="admin-title">관리자 메뉴</h2>
        </div>
        <nav className="admin-nav">
          {menuItems.map((item) => (
            <div
              key={item.name}
              className={`admin-nav-item ${activeMenu === item.name ? 'active' : ''}`}
              onClick={() => setActiveMenu(item.name)}
            >
              <span className="admin-nav-icon">{item.icon}</span>
              <span className="admin-nav-text">{item.name}</span>
            </div>
          ))}
        </nav>
      </div>
      <div className="admin-content">
        <div className="admin-content-header">
          <h3>{activeMenu || '관리자 대시보드'}</h3>
        </div>
        <div className="admin-content-body">
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default Admin;