// src/components/NoticeManagement.js
import React from 'react';

const NoticeManagement = () => {
  return (
    <div>
      <h1>공지사항 관리</h1>
      {/* 공지사항 관리 내용 추가 */}
    </div>
  );
};

export default NoticeManagement;