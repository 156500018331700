import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { FaPlus, FaTrash } from 'react-icons/fa';

const QuizCreator = () => {
  const [sentence, setSentence] = useState('');
  const [segments, setSegments] = useState([]);

  const handleSentenceChange = (e) => {
    setSentence(e.target.value);
    setSegments([e.target.value]);
  };

  const handleSegmentClick = (segmentIndex, lineIndex, e) => {
    const clickPosition = e.nativeEvent.offsetX / e.target.offsetWidth;
    const segment = segments[segmentIndex];
    const lines = segment.split('\n');
    const clickedLine = lines[lineIndex];
    
    const splitPosition = Math.floor(clickedLine.length * clickPosition);
    
    const leftPart = clickedLine.slice(0, splitPosition).trim();
    const rightPart = clickedLine.slice(splitPosition).trim();
  
    if (leftPart && rightPart) {
      lines[lineIndex] = leftPart + '\n' + rightPart;
      const newSegment = lines.join('\n');
      const newSegments = [
        ...segments.slice(0, segmentIndex),
        newSegment,
        ...segments.slice(segmentIndex + 1)
      ];
      setSegments(newSegments);
    }
  };

  const prepareQuizData = () => {
    return segments.flatMap(segment => 
      segment.split('\n').map((text, index) => ({
        id: `${segments.indexOf(segment)}-${index}`,
        text: text.trim(),
        order: index + 1
      }))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const db = getFirestore();
    try {
      const quizData = prepareQuizData();
      await addDoc(collection(db, 'quizplus'), {
        sentence: sentence,
        segments: quizData,
        createdAt: new Date()
      });
      console.log('Quiz saved successfully!');
      setSentence('');
      setSegments([]);
    } catch (error) {
      console.error('Error saving quiz:', error);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">문제 만들기</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={sentence}
          onChange={handleSentenceChange}
          placeholder="문장을 입력하세요"
          className="w-full p-2 border rounded mb-4"
          rows="3"
        />
        <div className="mb-4">
          {segments.map((segment, index) => (
            <div key={index} className="mb-2">
              {segment.split('\n').map((line, lineIndex) => (
                <span 
                  key={`${index}-${lineIndex}`}
                  className="bg-blue-100 p-2 m-1 rounded inline-block cursor-pointer"
                  onClick={(event) => handleSegmentClick(index, lineIndex, event)}
                >
                  {line}
                </span>
              ))}
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 text-white p-2 rounded"
        >
          문제 저장
        </button>
      </form>
    </div>
  );
};

export default QuizCreator;
