// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC1PU5pw2Qw6K0jFcqZyG6ocZx5jn4YPeQ",
    authDomain: "newsplash-8c00d.firebaseapp.com",
    projectId: "newsplash-8c00d",
    storageBucket: "newsplash-8c00d.appspot.com",
    messagingSenderId: "489523870347",
    appId: "1:489523870347:web:60b7a12db51bc5b93bf28e",
    measurementId: "G-F508DHQSWH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export { auth, provider, db };