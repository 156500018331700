import React, { useState } from 'react';


export function calculateRecommendationScore({ solvedCount, dontKnowCount, wrongCount, correctCount, lastSolved }) {
    const alpha = 1.5;  // 모름 횟수 강화 파라미터
    const gamma = 10;   // 정답 비율과 푼 횟수 감소 조절 파라미터
    const beta = 100;   // 오답 비율에 대한 가중치
    const currentTime = new Date().getTime();
    const lastSolvedTime = lastSolved ? new Date(lastSolved).getTime() : 0;
    const timeElapsed = lastSolvedTime ? (currentTime - lastSolvedTime) / (1000 * 60 * 60) : 0; // 시간 단위 계산
    const thirtyDaysInHours = 30 * 24; // 30일을 시간 단위로 계산
    const salt = (Math.random() - 0.5) * 0.05;  // -0.025 ~ +0.025 범위의 솔트 값
 
    if (solvedCount >= 4 && correctCount === solvedCount && timeElapsed <= thirtyDaysInHours) {
        return 0; // 추천하지 않음
    }

    if (solvedCount === 0) {
        return 200 + salt; // 한 번도 풀지 않은 경우, 매우 강하게 추천
    }

    if (solvedCount <= 2) {
        return 150 + salt; // 2번 이하로 풀었을 경우, 강하게 추천
    }


    const incorrectRate = solvedCount > 0 ? (wrongCount / solvedCount) : 0;
    const dontKnowRate = solvedCount > 0 ? ((dontKnowCount / solvedCount) * alpha) : 0;
    const correctRate = solvedCount > 0 ? (correctCount / solvedCount) : 0;
    const adjustedCorrectRate = correctRate * (solvedCount / (gamma + solvedCount));
    const timeWeight = timeElapsed / thirtyDaysInHours;

    const score = (beta * incorrectRate) + dontKnowRate - adjustedCorrectRate + (timeWeight * 0.1) + salt;
    
    return score;
}