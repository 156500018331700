import React, { useState, useEffect, useRef } from 'react';
import { FaRedo, FaCheck, FaTimes } from 'react-icons/fa';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const Option = ({ text, isSelected, onClick }) => {
  return (
    <div
      className={`bg-blue-100 p-2 m-1 rounded cursor-pointer hover:bg-blue-200 transition-all ${isSelected ? 'hidden' : ''}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

const SentenceBuilder = () => {
  const [fullSentence, setFullSentence] = useState([]);
  const [options, setOptions] = useState([]);
  const [sentence, setSentence] = useState([]);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [feedback, setFeedback] = useState('');
  const intervalRef = useRef(null);

  useEffect(() => {
    fetchQuiz();
  }, []);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [isRunning]);

  const fetchQuiz = async () => {
    const db = getFirestore();
    const quizzesCollection = collection(db, 'quizplus');
    const quizSnapshot = await getDocs(quizzesCollection);
    const quizList = quizSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    if (quizList.length > 0) {
      const randomQuiz = quizList[Math.floor(Math.random() * quizList.length)];
      const sentenceArray = randomQuiz.segments.map(segment => segment.text);
      setFullSentence(sentenceArray);
      resetProblem(sentenceArray);
    }
  };

  const resetProblem = (sentence = fullSentence) => {
    const blankIndices = getRandomBlankIndices(sentence.length, 3);
    const newSentence = sentence.map((word, index) =>
      blankIndices.includes(index) ? '' : word
    );
    const newOptions = blankIndices.map(index => sentence[index]);
    
    setSentence(newSentence);
    setOptions(newOptions.sort(() => Math.random() - 0.5));
    setTimer(0);
    setIsRunning(false);
    setFeedback('');
  };

  const getRandomBlankIndices = (max, count) => {
    const indices = [];
    while (indices.length < count) {
      const index = Math.floor(Math.random() * max);
      if (!indices.includes(index)) {
        indices.push(index);
      }
    }
    return indices;
  };



  const checkSolution = () => {
    const isCorrect = sentence.every((word, index) => word === fullSentence[index]);
    setFeedback(isCorrect ? '정답입니다!' : '다시 시도해보세요!');
    setIsRunning(false);
  };

  const handleOptionClick = (option) => {
    if (!isRunning) {
      setIsRunning(true);
    }
    const emptyIndex = sentence.findIndex(word => word === '');
    if (emptyIndex !== -1) {
      const newSentence = [...sentence];
      newSentence[emptyIndex] = option;
      setSentence(newSentence);
      setOptions(options.filter(opt => opt !== option));
    }
  };

  const handleSentenceClick = (index) => {
    const newSentence = [...sentence];
    const removedWord = newSentence[index];
    if (removedWord) {
      newSentence[index] = '';
      setSentence(newSentence);
      setOptions([...options, removedWord]);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-xl select-none">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">문장 빈칸 채우기</h1>
      
      <div className="mb-6">
        <p className="text-lg text-gray-700">빈칸에 알맞은 단어를 넣어 문장을 완성하세요:</p>
        <div className="flex flex-wrap justify-center items-center space-x-2 mt-4 min-h-[40px] bg-gray-100 p-2 rounded">
          {sentence.map((word, index) => (
            <div
              key={`sentence-${index}`}
              className={`p-2 m-1 rounded flex items-center ${word ? 'bg-blue-200 hover:bg-blue-300' : 'bg-gray-300'} transition-colors min-w-[60px] justify-center cursor-pointer`}
              onClick={() => handleSentenceClick(index)}
            >
              {word || '_____'}
            </div>
          ))}
        </div>
      </div>
      
      <div className="flex justify-center mb-6 flex-wrap">
        {options.map((option, index) => (
          <Option 
            key={`option-${index}`} 
            text={option} 
            isSelected={sentence.includes(option)}
            onClick={() => handleOptionClick(option)} 
          />
        ))}
      </div>
      
      <div className="flex justify-between items-center mb-6">
        <div className="text-xl font-semibold">
          시간: {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
        </div>
        <button
          onClick={() => resetProblem()}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
        >
          <FaRedo className="mr-2" />
          리셋
        </button>
      </div>
      
      <div className="text-center">
        <button
          onClick={checkSolution}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center mx-auto"
        >
          <FaCheck className="mr-2" />
          정답 확인
        </button>
        {feedback && (
          <div className={`mt-4 text-lg font-semibold ${feedback === '정답입니다!' ? 'text-green-600' : 'text-red-600'}`}>
            {feedback === '정답입니다!' ? (
              <FaCheck className="inline mr-2" />
            ) : (
              <FaTimes className="inline mr-2" />
            )}
            {feedback}
          </div>
        )}
      </div>
    </div>
  );
};


export default SentenceBuilder;