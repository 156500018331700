// src/reducers/quizReducer.js
const initialState = {
  answers: {},
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_QUIZ_ANSWER':
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.quizId]: action.payload.answer,
        },
      };
    default:
      return state;
  }
};

export default quizReducer;