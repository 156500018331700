// src/components/FillCategoryPath.js
import React, { useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';

const FillCategoryPath = () => {
  const [status, setStatus] = useState('idle');

  const startFillingCategoryPath = async () => {
    setStatus('loading');
    try {
      const quizzesSnapshot = await getDocs(collection(db, 'quizzes'));
      const quizzes = quizzesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      for (const quiz of quizzes) {
        if (!quiz.categoryPath) {
          const categoryPath = await generateCategoryPath(quiz.category);
          if (categoryPath) {
            await updateDoc(doc(db, 'quizzes', quiz.id), { categoryPath });
          }
        }
      }

      setStatus('completed');
    } catch (error) {
      console.error('Error filling category path:', error);
      setStatus('error');
    }
  };

  const generateCategoryPath = async (categoryId) => {
    try {
      const docRef = doc(db, '1stCategories', categoryId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data().name;
      } else {
        const firstCategoriesSnapshot = await getDocs(collection(db, '1stCategories'));
        for (const firstDoc of firstCategoriesSnapshot.docs) {
          const firstDocName = firstDoc.data().name;

          const secondCategoriesSnapshot = await getDocs(collection(firstDoc.ref, '2ndCategories'));
          for (const secondDoc of secondCategoriesSnapshot.docs) {
            const secondDocName = secondDoc.data().name;

            if (secondDoc.id === categoryId) {
              return `${firstDocName} - ${secondDocName}`;
            }

            const thirdCategoriesSnapshot = await getDocs(collection(secondDoc.ref, '3rdCategories'));
            for (const thirdDoc of thirdCategoriesSnapshot.docs) {
              if (thirdDoc.id === categoryId) {
                return `${firstDocName} - ${secondDocName} - ${thirdDoc.data().name}`;
              }
            }
          }
        }
      }

      return null;
    } catch (error) {
      console.error("Error finding document:", error);
      return null;
    }
  };

  return (
    <div className="fill-category-path">
      <h1>카테고리명 채우기</h1>
      {status === 'idle' && <button onClick={startFillingCategoryPath}>Start</button>}
      {status === 'loading' && <p>Loading...</p>}
      {status === 'completed' && <p>완료되었습니다!</p>}
      {status === 'error' && <p>오류가 발생했습니다. 콘솔을 확인해주세요.</p>}
    </div>
  );
};

export default FillCategoryPath;
