import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Editor, EditorState, RichUtils, ContentState, Modifier, SelectionState } from 'draft-js';
import { db } from '../firebaseConfig';
import { doc, updateDoc, collection, getDocs, query, orderBy } from 'firebase/firestore';
import 'draft-js/dist/Draft.css';
import './QuizManagement.css';

const QuizEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { quiz } = location.state || {};
  const [categories, setCategories] = useState([]);
  const [selected1stCategory, setSelected1stCategory] = useState(quiz?.category || '');
  const [selected2ndCategory, setSelected2ndCategory] = useState('');
  const [selected3rdCategory, setSelected3rdCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [quizType, setQuizType] = useState(quiz?.type || 'a');
  const [answer, setAnswer] = useState(quiz?.answer || '');
  const [tag, setTag] = useState(quiz?.tag || '');
  const [premise, setPremise] = useState(quiz?.premise || '');
  const [options, setOptions] = useState(quiz?.options || ['', '', '', '', '']);
  const [dTypeAnswers, setDTypeAnswers] = useState(quiz?.dTypeAnswers || []);
  const [num, setNum] = useState(quiz?.num || '');
  const [errors, setErrors] = useState({});
  const [selected1stCategoryName, setSelected1stCategoryName] = useState('');
  const [selected2ndCategoryName, setSelected2ndCategoryName] = useState('');
  const [selected3rdCategoryName, setSelected3rdCategoryName] = useState('');

  const createEditorState = (content, underlines) => {
    let editorState = EditorState.createEmpty();
    
    if (content) {
      let contentState = ContentState.createFromText(content);
      editorState = EditorState.createWithContent(contentState);
    }
  
    if (underlines && Array.isArray(underlines) && underlines.length > 0) {
      let contentState = editorState.getCurrentContent();
      underlines.forEach(underline => {
        if (underline && typeof underline.start === 'number' && typeof underline.end === 'number') {
          const blockKey = contentState.getFirstBlock().getKey();
          const selectionState = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: underline.start,
            focusOffset: underline.end,
          });
          contentState = Modifier.applyInlineStyle(contentState, selectionState, 'UNDERLINE');
        }
      });
      editorState = EditorState.createWithContent(contentState);
    }
  
    return editorState;
  };
  
  const [questionEditorState, setQuestionEditorState] = useState(() => 
    createEditorState(quiz?.question, quiz?.underlineSelections?.question)
  );
  const [explanationEditorState, setExplanationEditorState] = useState(() => 
    createEditorState(quiz?.explanation, quiz?.underlineSelections?.explanation)
  );
  const [comparisonEditorState, setComparisonEditorState] = useState(() => 
    createEditorState(quiz?.comparison, quiz?.underlineSelections?.comparison)
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(query(collection(db, '1stCategories'), orderBy('createdAt', 'asc')));
    const categoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCategories(categoriesData);
  };

  const fetchSubCategories = async (categoryId, categoryName) => {
    setSelected1stCategoryName(categoryName);
    const q = query(collection(db, `1stCategories/${categoryId}/2ndCategories`), orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subCategoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setSubCategories(subCategoriesData);
    if (subCategoriesData.length === 0) {
      setSelected2ndCategory(categoryId);
      setSelected3rdCategory(categoryId);
    }
  };

  const fetchSubSubCategories = async (categoryId, categoryName) => {
    setSelected2ndCategoryName(categoryName);
    const q = query(collection(db, `1stCategories/${selected1stCategory}/2ndCategories/${categoryId}/3rdCategories`), orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subSubCategoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setSubSubCategories(subSubCategoriesData);
    if (subSubCategoriesData.length === 0) {
      setSelected3rdCategory(categoryId);
    }
  };

  const validateQuiz = () => {
    let isValid = true;
    let newErrors = {};

    // Add validation logic here

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdateQuiz = async () => {
    if (!validateQuiz()) {
      return;
    }

    const quizRef = doc(db, 'quizzes', quiz.id);
    await updateDoc(quizRef, {
      question: questionEditorState.getCurrentContent().getPlainText(),
      answer: quizType === 'a' || quizType === 'b' ? answer === 'true' : answer,
      explanation: explanationEditorState.getCurrentContent().getPlainText(),
      comparison: comparisonEditorState.getCurrentContent().getPlainText(),
      tag,
      premise,
      options,
      dTypeAnswers,
      category: selected3rdCategory || selected2ndCategory || selected1stCategory,
      type: quizType,
      num: parseInt(num),
      underlineSelections: {
        question: getUnderlines(questionEditorState),
        explanation: getUnderlines(explanationEditorState),
        comparison: getUnderlines(comparisonEditorState)
      }
    });
    alert('문제가 성공적으로 수정되었습니다!');
    navigate(-1);
  };

  const handleEditorChange = (editorState, setEditorState) => {
    setEditorState(editorState);
  };

  const toggleUnderline = (editorState, setEditorState) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const getUnderlines = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    let underlines = [];
    let currentUnderline = null;
    let absoluteOffset = 0;
  
    blocks.forEach((block, blockIndex) => {
      const text = block.getText();
      const characterList = block.getCharacterList();
  
      characterList.forEach((char, index) => {
        if (char.hasStyle('UNDERLINE')) {
          if (!currentUnderline) {
            currentUnderline = { start: absoluteOffset + index, text: text[index] };
          } else {
            currentUnderline.text += text[index];
          }
        } else if (currentUnderline) {
          currentUnderline.end = absoluteOffset + index;
          underlines.push(currentUnderline);
          currentUnderline = null;
        }
      });
  
      if (currentUnderline) {
        currentUnderline.end = absoluteOffset + text.length;
        underlines.push(currentUnderline);
        currentUnderline = null;
      }
  
      absoluteOffset += text.length + 1; // +1 for the newline character
    });
  
    return underlines;
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleDTypeAnswerChange = (index, field, value) => {
    const newDTypeAnswers = [...dTypeAnswers];
    newDTypeAnswers[index] = { ...newDTypeAnswers[index], [field]: value };
    setDTypeAnswers(newDTypeAnswers);
  };

  const setBlank = () => {
    const selectionState = questionEditorState.getSelection();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = questionEditorState.getCurrentContent().getPlainText().slice(start, end);

    if (start !== end) {
      const newDTypeAnswer = { start, end, text: selectedText };
      setDTypeAnswers([...dTypeAnswers, newDTypeAnswer]);

      const contentState = Modifier.replaceText(
        questionEditorState.getCurrentContent(),
        selectionState,
        '_'.repeat(end - start)
      );

      const newEditorState = EditorState.push(questionEditorState, contentState, 'insert-characters');
      setQuestionEditorState(newEditorState);
    }
  };

  return (
    <div className="quiz-management">
      <h1>문제 수정</h1>
      <div className="form-group">
        <label>1차 카테고리 선택</label>
        <select
          value={selected1stCategory}
          onChange={(e) => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            setSelected1stCategory(e.target.value);
            fetchSubCategories(e.target.value, selectedOption.text);
          }}
          className={errors.selected1stCategory ? 'error' : ''}
        >
          <option value="">카테고리 선택</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
        {errors.selected1stCategory && <span className="error-message">{errors.selected1stCategory}</span>}
      </div>
      {/* Add 2nd and 3rd category selects similar to QuizManagement */}
      <div className="form-group">
        <label>문제 유형</label>
        <select value={quizType} onChange={(e) => setQuizType(e.target.value)}>
          <option value="a">Type A (True/False)</option>
          <option value="b">Type B (Premise + True/False)</option>
          <option value="c">Type C (Multiple Choice)</option>
          <option value="d">Type D (Fill in the Blank)</option>
        </select>
      </div>
      <div className="form-group">
        <label>문제</label>
        <div className="editor-container">
          <Editor
            editorState={questionEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setQuestionEditorState)}
          />
          {quizType === 'd' && (
            <button onClick={setBlank}>빈칸설정</button>
          )}
          <button onClick={() => toggleUnderline(questionEditorState, setQuestionEditorState)}>밑줄 설정</button>
        </div>
      </div>
      {quizType === 'b' && (
        <div className="form-group">
          <label>기본 지문</label>
          <textarea
            className="content-display"
            value={premise}
            onChange={(e) => setPremise(e.target.value)}
          />
        </div>
      )}
      {quizType === 'c' && (
        <div className="form-group">
          <label>보기</label>
          {options.map((option, index) => (
            <textarea
              key={index}
              className="content-display"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              placeholder={`Option ${index + 1}`}
            />
          ))}
        </div>
      )}
      {quizType === 'd' && (
        <div className="form-group">
          <label>정답 (빈칸 채우기)</label>
          {dTypeAnswers.map((answer, index) => (
            <div key={index} className="d-type-answer">
              <input
                type="number"
                value={answer.start}
                onChange={(e) => handleDTypeAnswerChange(index, 'start', parseInt(e.target.value))}
                placeholder="Start"
              />
              <input
                type="number"
                value={answer.end}
                onChange={(e) => handleDTypeAnswerChange(index, 'end', parseInt(e.target.value))}
                placeholder="End"
              />
              <textarea
                className="content-display"
                value={answer.text}
                onChange={(e) => handleDTypeAnswerChange(index, 'text', e.target.value)}
                placeholder="Text"
              />
            </div>
          ))}
          <button onClick={() => setDTypeAnswers([...dTypeAnswers, { start: '', end: '', text: '' }])}>
            빈칸 추가
          </button>
        </div>
      )}
      <div className="form-group">
        <label>정답</label>
        {quizType === 'a' || quizType === 'b' ? (
          <div className="true-false-buttons">
            <button
              className={`true-false-button ${answer === true ? 'selected' : ''}`}
              onClick={() => setAnswer(true)}
            >
              O
            </button>
            <button
              className={`true-false-button ${answer === false ? 'selected' : ''}`}
              onClick={() => setAnswer(false)}
            >
              X
            </button>
          </div>
        ) : quizType === 'c' ? (
          <select value={answer} onChange={(e) => setAnswer(e.target.value)}>
            <option value="">정답 선택</option>
            {options.map((_, index) => (
              <option key={index} value={index + 1}>{index + 1}</option>
            ))}
          </select>
        ) : null}
      </div>
      <div className="form-group">
        <label>설명</label>
        <div className="editor-container">
          <Editor
            editorState={explanationEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setExplanationEditorState)}
          />
          <button onClick={() => toggleUnderline(explanationEditorState, setExplanationEditorState)}>밑줄 설정</button>
        </div>
      </div>
      <div className="form-group">
        <label>비교 (선택사항)</label>
        <div className="editor-container">
          <Editor
            editorState={comparisonEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setComparisonEditorState)}
          />
          <button onClick={() => toggleUnderline(comparisonEditorState, setComparisonEditorState)}>밑줄 설정</button>
        </div>
      </div>
      <div className="form-group">
        <label>태그 (선택사항)</label>
        <input
          type="text"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>문제 번호</label>
        <input
          type="number"
          value={num}
          onChange={(e) => setNum(e.target.value)}
        />
      </div>
      <button onClick={() => navigate(-1)}>취소</button>
      <p></p>
      <button onClick={handleUpdateQuiz}>수정</button>
    </div>
  );
};

export default QuizEdit;
