// src/components/Navbar.js
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setUser, clearUser } from "../store";
import { signInWithPopup, signOut } from "firebase/auth";
import { auth, provider } from "../firebaseConfig";
import { db } from '../firebaseConfig';
import { doc, getDoc,updateDoc } from 'firebase/firestore';
import home from "../img/home.svg";
import quiz from "../img/quiz.svg";
import saved from "../img/saved.svg";
import profile from "../img/profile.svg";
import fontsetting from "../img/fontsetting.svg";
import logout from "../img/logout.svg";
import login from "../img/login.svg";
import signup from "../img/signup.svg";
import adminIcon from "../img/manager.svg";
import searchIcon from "../img/find.svg";
import './Navbar.css';
import civilLawData from './civilLaw.json';
import { useDebounce } from './useDebounce';
import { createExactMatcher, highlightMatch } from './choseongSearch';
import { serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Navbar = ({
  currentView,
  isSidebarOpen,
  setIsSidebarOpen,
  mainsubCategories,
  setSelectedmainCategory,
  selectedMainCategory,
  selectedSubCategory,
  isRandomOrder,
}) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const [userRole, setUserRole] = useState(null);
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const rightSidebarRef = useRef(null);
  const [overlay, setOverlay] = useState(false);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(civilLawData);
  const debouncedSearchTerm = useDebounce(searchTerm, 200);



  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  function createFuzzyMatcher(input) {
    const pattern = input.split('').map(escapeRegExp).join('.*?');
    return new RegExp(pattern);
  }

  const handleHamburgerClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setOverlay(!overlay);
  };

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      dispatch(setUser(result.user));
      fetchUserRole(result.user.uid);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        
        if (docSnap.exists()) {
          await updateDoc(userRef, {
            lastLogoutTime: serverTimestamp(),
            isActive: false
          });
        } else {
          console.log('User document does not exist. Skipping update.');
        }
      }
      
      await signOut(auth);
      dispatch(clearUser());
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  
  

  const fetchUserRole = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      const adminDoc = await getDoc(doc(db, 'admin', uid));
      if (adminDoc.exists() && adminDoc.data().role === 'admin') {
        setUserRole('admin');
      } else if (userDoc.exists()) {
        setUserRole(userDoc.data().role || null);
      } else {
        setUserRole(null);
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
      setUserRole(null);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserRole(user.uid);
    }
  }, [user]);

  const handleClickOutside = (event) => {
    if (isSidebarOpen && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
      setOverlay(false);
    }
    if (isRightSidebarOpen && !rightSidebarRef.current.contains(event.target)) {
      setIsRightSidebarOpen(false);
      setOverlay(false);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  useEffect(() => {
    if (debouncedSearchTerm.trim() === '') {
      setSearchResults(civilLawData);
    } else {
      const regex = createExactMatcher(debouncedSearchTerm);
      const results = civilLawData.filter(item => {
        return regex.test(item.num) || 
               regex.test(item.subtitle) || 
               regex.test(item.label) || 
               regex.test(item.title) || 
               item.contents.some(content => regex.test(content));
      }).map(item => ({
        ...item,
        num: highlightMatch(item.num, regex),
        subtitle: highlightMatch(item.subtitle, regex),
        label: highlightMatch(item.label, regex),
        title: highlightMatch(item.title, regex),
        contents: item.contents.map(content => highlightMatch(content, regex))
      }));
      setSearchResults(results);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (isSidebarOpen || isRightSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen, isRightSidebarOpen]);

  return (
    <nav className="nav-bar">
      <div className={`overlay ${overlay ? 'active' : ''}`} onClick={handleClickOutside}></div>

      <div className="nav-content">
        <button onClick={handleHamburgerClick} className={`hamburger-button ${isSidebarOpen ? 'rotated' : ''}`}>
          ☰
        </button>
        <h1 className="nav-title">
          {currentView === 'subCategories' ? '퀴즈 풀기' :
            (currentView === 'categoryManagement' ? '관리자 - 카테고리 관리' :
              (currentView === 'admin' ? '관리자 메뉴' : ''))}
        </h1>
      </div>
      <button className="right-sidebar-button" onClick={() => {
        setIsRightSidebarOpen(true);
        setOverlay(true);
      }}>
        <img src={searchIcon} alt="Search" />
      </button>
      <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button className="close-sidebar" onClick={() => {
          setIsSidebarOpen(false);
          setOverlay(false);
        }}>×</button>
        <div className="sidebar-content">
          <div className="item" onClick={() => {
            setIsSidebarOpen(false);
            setOverlay(false);
          }}>
            <Link to="/">
              <div className="menu-item">
                <img className="svg-icon" alt="" src={home} />
                <div className="home-wrapper">
                  <b className="b">Home</b>
                </div>
              </div>
            </Link>
          </div>
          {user && userRole === 'admin' && (
            <>
              <div className="item" onClick={() => {
                if (mainsubCategories.length > 0) {
                  setSelectedmainCategory(mainsubCategories[0].name);
                }
                setIsSidebarOpen(false);setOverlay(false);
              }}>
                <Link to="/quiz-selecting">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={quiz} />
                    <div className="wrapper">
                      <b className="b">퀴즈 풀기</b>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item" onClick={() => {
                setIsSidebarOpen(false);
                setOverlay(false);
              }}>
                <Link to="/saved">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={saved} />
                    <div className="wrapper">
                      <b className="b">저장된 문제</b>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item" onClick={() => {
                setIsSidebarOpen(false);
                setOverlay(false);
              }}>
                <Link to="/profile">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={profile} />
                    <div className="frame">
                      <b className="b">내 프로필</b>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item" onClick={() => {
                setIsSidebarOpen(false);
                setOverlay(false);
              }}>
                <div className="menu-item">
                  <img className="svg-icon" alt="" src={fontsetting} />
                  <div className="frame-div">
                    <b className="b">폰트 설정</b>
                  </div>
                </div>
              </div>
              <div className="item" onClick={() => {
                setIsSidebarOpen(false);
                setOverlay(false);
              }}>
                <Link to="/admin">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={adminIcon} />
                    <div className="wrapper">
                      <b className="b">관리자 메뉴</b>
                    </div>
                  </div>
                </Link>
              </div>
              {/*<div className="item" onClick={() => {setIsSidebarOpen(false); setOverlay(false);}}>
                <Link to="/quiz-creator">
                  <div className="menu-item">
                    <div className="wrapper">
                      <b className="b">퀴즈 만들기</b>
                    </div>
                  </div>
                </Link>
              </div>
              
              <div className="item" onClick={() => {setIsSidebarOpen(false); setOverlay(false);}}>
                <Link to="/quiz-plus">
                  <div className="menu-item">
                    <div className="wrapper">
                      <b className="b">퀴즈 플러스</b>
                    </div>
                  </div>
                </Link>
              </div>*/}
              <div className="item" onClick={() => { handleLogout(); setIsSidebarOpen(false); setOverlay(false);}}>
                <div className="menu-item">
                  <img className="svg-icon" alt="" src={logout} />
                  <div className="wrapper">
                    <b className="b">로그아웃</b>
                  </div>
                </div>
              </div>
            </>
          )}
          {user && userRole !== 'admin' && userRole && (
            <>
              <div className="item" onClick={() => {
                if (mainsubCategories.length > 0) {
                  setSelectedmainCategory(mainsubCategories[0].name);
                }
                setIsSidebarOpen(false);
                setOverlay(false);
              }}>
                <Link to="/quiz-selecting">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={quiz} />
                    <div className="wrapper">
                      <b className="b">퀴즈 풀기</b>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item" onClick={() => {setIsSidebarOpen(false); setOverlay(false);}}>
                <Link to="/saved">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={saved} />
                    <div className="wrapper">
                      <b className="b">저장된 문제</b>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item" onClick={() => {setIsSidebarOpen(false); setOverlay(false);}}>
                <Link to="/profile">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={profile} />
                    <div className="frame">
                      <b className="b">내 프로필</b>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item" onClick={() => {setIsSidebarOpen(false); setOverlay(false);}}>
                <div className="menu-item">
                  <img className="svg-icon" alt="" src={fontsetting} />
                  <div className="frame-div">
                    <b className="b">폰트 설정</b>
                  </div>
                </div>
              </div>
              <div className="item" onClick={() => { handleLogout(); setIsSidebarOpen(false); setOverlay(false); }}>
                <div className="menu-item">
                  <img className="svg-icon" alt="" src={logout} />
                  <div className="wrapper">
                    <b className="b">로그아웃</b>
                  </div>
                </div>
              </div>
            </>
          )}
          {user && (!userRole || userRole === null) && (
            <>
              <div className="item" onClick={() => { handleLogout(); setIsSidebarOpen(false); setOverlay(false); }}>
                <div className="menu-item">
                  <img className="svg-icon" alt="" src={logout} />
                  <div className="wrapper">
                    <b className="b">로그아웃</b>
                  </div>
                </div>
              </div>
            </>
          )}
          {!user && (
            <>
              <div className="item" onClick={() => { setIsSidebarOpen(false); setOverlay(false); }}>
                <Link to="/login">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={login} />
                    <div className="wrapper">
                      <b className="b">로그인</b>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="item" onClick={() => { setIsSidebarOpen(false); setOverlay(false); }}>
                <Link to="/register">
                  <div className="menu-item">
                    <img className="svg-icon" alt="" src={signup} />
                    <div className="wrapper">
                      <b className="b">회원가입</b>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div ref={rightSidebarRef} className={`right-sidebar ${isRightSidebarOpen ? 'open' : ''}`}>
        <button className="close-sidebar" onClick={() => {
          setIsRightSidebarOpen(false);
          setOverlay(false);
        }}>×</button>
        <input 
          type="text" 
          placeholder="Search..." 
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <div className="search-results">
          {searchResults.map((item, index) => (
            <div key={index} className="search-item">
              <p className="searchLabel">
                <span dangerouslySetInnerHTML={{ __html: `${item.label}` }}></span>
                <span dangerouslySetInnerHTML={{ __html: ` (${item.title})` }}></span>
              </p>
              {item.contents.map((content, i) => (
                <p className="searchContent" key={i} dangerouslySetInnerHTML={{ __html: content }}></p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
