import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc, getDocs, updateDoc, setDoc, arrayUnion, arrayRemove, serverTimestamp } from 'firebase/firestore';
import './QuizResultAnalysis.css';
import { collection } from 'firebase/firestore';




const QuizResultAnalysis = ({ quizStates, quizzes, answers = [], savedQuizzes = {} }) => {
  const [quizStats, setQuizStats] = useState({});
  const [savedQuizzesState, setSavedQuizzesState] = useState(savedQuizzes);
  const navigate = useNavigate();
  const hasWrongOrDontKnowAnswers = quizStates.some(state => state.isCorrect === false || state.isCorrect === null);




  useEffect(() => {
    console.log('QuizStates:', quizStates);
    console.log('Quizzes:', quizzes);
    console.log('Answers:', answers);
    console.log('Saved Quizzes:', savedQuizzes);

    const fetchQuizStats = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const stats = {};
      for (const quiz of quizzes) {
        const userQuizRef = doc(db, 'userQuizStats', user.uid,'quizzes',quiz.id);
        const userQuizSnap = await getDoc(userQuizRef);
        if (userQuizSnap.exists()) {
          stats[quiz.id] = userQuizSnap.data();
        } else {
          stats[quiz.id] = { solvedCount: 0, wrongCount: 0, dontKnowCount: 0 };
        }
      }
      setQuizStats(stats);
    };

    fetchQuizStats();
  }, [quizStates, quizzes, answers, savedQuizzes]);

  const totalQuestions = quizzes.length;

  const correctAnswers = quizStates.filter(state => state.isCorrect === true).length;
  const wrongAnswers = quizStates.filter(state => state.isCorrect === false).length;
  const dontKnowAnswers = quizStates.filter(state => state.isCorrect === null).length;

  const correctPercentage = ((correctAnswers / totalQuestions) * 100).toFixed(2);
  const wrongPercentage = ((wrongAnswers / totalQuestions) * 100).toFixed(2);
  const dontKnowPercentage = ((dontKnowAnswers / totalQuestions) * 100).toFixed(2);

  const data = [
    { name: '정답', value: correctAnswers },
    { name: '오답', value: wrongAnswers },
    { name: '모름', value: dontKnowAnswers }
  ];

  const COLORS = ['#36A2EB', '#FF6384', '#FFCE56'];

  const toggleSaveQuiz = async (quizId, quiz) => {
    const user = auth.currentUser;
    if (!user) {
      console.error("User is not authenticated");
      return;
    }
  
    // Find the quiz in the categories
    let categoryPath = quiz.categoryPath;
    let question = quiz.question;
    let num = quiz.num;
  
    const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', quizId);
    const userQuizSnap = await getDoc(userQuizRef);
    const isCurrentlySaved = userQuizSnap.exists() && userQuizSnap.data().isSaved;
  
    await updateDoc(userQuizRef, {
      isSaved: !isCurrentlySaved,
      categoryPath,
      question,
      num
    });
  
    setSavedQuizzesState(prev => ({ ...prev, [quizId]: !isCurrentlySaved }));
  
    const userSavedRef = doc(db, 'users_saved', user.uid);
    if (isCurrentlySaved) {
      await updateDoc(userSavedRef, {
        quizzes: arrayRemove(quizId)
      });
    } else {
      await setDoc(userSavedRef, {
        quizzes: arrayUnion(quizId)
      }, { merge: true });
    }
  };
  

  const handleRetryWrongQuizzes = () => {
    const wrongAndDontKnowQuizzes = quizzes.filter((quiz, index) => quizStates[index].isCorrect === false || quizStates[index].isCorrect === null);
    navigate('/quiz-solving', {
      state: {
        selectedNums: wrongAndDontKnowQuizzes.map(quiz => ({ num: quiz.num, categoryId: quiz.category }))
      }
    });
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };
  
  
  const handleExit = () => {
    navigate('/quiz-selecting'); // 퀴즈 고르는 페이지로 이동
  };

  return (
    <div className="quiz-result-analysis">
      <h2>퀴즈 결과 분석</h2>
      <div className="result-summary">
        <div className="text-summary">
          <p>총 문제 수: {totalQuestions}</p>
          <p>정답 수: {correctAnswers} ({correctPercentage}%)</p>
          <p>오답 수: {wrongAnswers} ({wrongPercentage}%)</p>
          <p>모름: {dontKnowAnswers} ({dontKnowPercentage}%)</p>
        </div>
        <div className="chart-container">
          <PieChart width={300} height={300}>
            <Pie
              data={data}
              cx={150}
              cy={150}
              labelLine={false}
              label={({ name, percent }) => `${name}: ${Math.round(percent * 100)}%`}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => Math.round(value)} />
            <Legend />
          </PieChart>
        </div>
      </div>
      <h3>문제별 분석</h3>
      <ul className="quiz-result-list">
        {quizzes.map((quiz, index) => {
          const correctAnswer = answers.find(a => a.id === quiz.id)?.answer;
          const isSaved = savedQuizzesState[quiz.id];
          const stats = quizStats[quiz.id] || { solvedCount: 0, wrongCount: 0, dontKnowCount: 0 };
          const barData = [
            { name: '푼 횟수', value: stats.solvedCount, color: '#36A2EB' },
            { name: '오답 횟수', value: stats.wrongCount, color: '#FF6384' },
            { name: '모름 횟수', value: stats.dontKnowCount, color: '#FFCE56' }
          ];
          return (
            <li key={quiz.id} className={`quiz-item ${quizStates[index].isCorrect ? 'correct' : quizStates[index].isCorrect === false ? 'wrong' : 'dont-know'}`}>
              <div className={`quiz-card ${quizStates[index].isCorrect ? 'correct' : quizStates[index].isCorrect === false ? 'wrong' : 'dont-know'}`}>
              <div className="quiz-header0">
                  <div className="quiz-question">
                  <span
                    className={`star-icon2 fas fa-star ${isSaved ? 'saved' : ''}`}
                    onClick={() => toggleSaveQuiz(quiz.id, quiz)}



                    ></span>
                    <span className="question-number">[문제 {index + 1}]</span>
                  </div>
                </div>
                <div className="quiz-header">
                  <div className="quiz-question">

                    <span className="question-text">{quiz.question}</span>
                  </div>
                </div>
                <div className="quiz-details">
                  <div className="answer-result">
                    <p><strong>정답:</strong> {correctAnswer}</p>
                    <p className={`result ${quizStates[index].isCorrect ? 'correct' : quizStates[index].isCorrect === false ? 'wrong' : 'dont-know'}`}>
                      <strong>결과:</strong> {quizStates[index].isCorrect ? '정답' : quizStates[index].isCorrect === false ? '오답' : '모름'}
                    </p>
                  </div>
                  <div className="bar-chart-container">
    <BarChart
      width={200}
      height={130}
      data={barData}
      layout="vertical"
      margin={{
        top: 5,
        right: 5,
        left: 5,
        bottom: 5,
      }}
    >
    <CartesianGrid strokeDasharray="3 3" />
    <YAxis type="category" dataKey="name" />
    <XAxis type="number" tickFormatter={(value) => Math.round(value)} />
    <Tooltip formatter={(value) => Math.round(value)} />
    <Legend />
    <Bar dataKey="value">
      {barData.map((entry, index) => (
        <Cell key={`cell-${index}-${entry.name}`} fill={entry.color} />
      ))}
    </Bar>
  </BarChart>

</div>

                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <div className="button-container">
  <button 
    onClick={handleRetryWrongQuizzes} 
    className="retry-wrong-quizzes"
    hidden={!hasWrongOrDontKnowAnswers}
  >
    틀린 문제만 다시 풀기
  </button>
  <button onClick={handleExit} className="exit-button">
    종료
  </button>
</div>

    </div>
  );
};

export default QuizResultAnalysis;
