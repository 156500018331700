import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { db, auth } from '../firebaseConfig';
import { collection, getDocs, doc, getDoc, query, where, setDoc, runTransaction, updateDoc, serverTimestamp, arrayUnion, arrayRemove, limit, writeBatch } from 'firebase/firestore';
import './QuizSolving.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ColorPicker from './ColorPicker';
import QuizResultAnalysis from './QuizResultAnalysis';
import { setQuizAnswer } from '../actions/quizActions';
import LoadingSpinner from './LoadingSpinner';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Modal } from 'react-bootstrap';






const QuizSolving = ({ quiz, onBackToList }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quizzes, setQuizzes] = useState([]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [quizStates, setQuizStates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryPath, setCategoryPath] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [savedQuizzes, setSavedQuizzes] = useState({});
  const [memo, setMemo] = useState('');
  const [isEditingMemo, setIsEditingMemo] = useState(false);
  const [highlights, setHighlights] = useState([]);
  const [underlines, setUnderlines] = useState([]);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [highlightType, setHighlightType] = useState(null);
  const [selectionPosition, setSelectionPosition] = useState({ x: 0, y: 0 });
  const [currentSelection, setCurrentSelection] = useState(null);
  const [colorPalette, setColorPalette] = useState([]);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentQuiz = quizzes[currentQuizIndex] || quiz;
  const currentQuizState = quizStates[currentQuizIndex] || {};
  const [isSaved, setIsSaved] = useState();
  const scrollY = window.scrollY || document.documentElement.scrollTop;
  const [similarQuiz, setSimilarQuiz] = useState(null);


  const questionRef = useRef(null);
  const explanationRef = useRef(null);
  const comparisonRef = useRef(null);
  const memoRef = useRef(null);
  const colorPickerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);


  const { category, subCategory, subSubCategory, quizCount, isRandom, selectedNums, fromSavedQuizzes } = location.state || {};
  const [selectionStart, setSelectionStart] = useState({ x: 0, y: 0 });
  const [selectionEnd, setSelectionEnd] = useState({ x: 0, y: 0 });

  const [relatedQuizzes, setRelatedQuizzes] = useState([]);
  const [currentRelatedIndex, setCurrentRelatedIndex] = useState(0);
  const [showRelatedModal, setShowRelatedModal] = useState(false);
  // 유사 문제를 가져오는 함수 추가
  const fetchRelatedQuizzes = async (quizId) => {
    try {
      const relatedQuizzesDoc = await getDoc(doc(db, 'similarQuizzes', quizId));
      if (relatedQuizzesDoc.exists()) {
        const relatedQuizIds = relatedQuizzesDoc.data().similarQuizIds;
        const quizzes = [];
        for (const id of relatedQuizIds) {
          const quizDoc = await getDoc(doc(db, 'quizzes', id));
          if (quizDoc.exists()) {
            quizzes.push({ id: quizDoc.id, ...quizDoc.data() });
          }
        }
        setRelatedQuizzes(quizzes);
      }
    } catch (error) {
      console.error('Error fetching related quizzes:', error);
    }
  };
  // useEffect 추가
  useEffect(() => {
    if (currentQuiz) {
      fetchRelatedQuizzes(currentQuiz.id);
    }
  }, [currentQuiz]);

  // 다음 및 이전 버튼 핸들러 추가
  const handleNextRelated = () => {
    setCurrentRelatedIndex((prevIndex) => Math.min(prevIndex + 1, relatedQuizzes.length - 1));
  };

  const handlePrevRelated = () => {
    setCurrentRelatedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };


  const CustomModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="custom-modal-overlay">
        <div className="custom-modal">
          <button className="close-modal-button" onClick={onClose}>×</button>
          {children}
        </div>
      </div>
    );
  };


  const handleFindRelated = async () => {
    if (!currentQuiz) {
      console.error('Current quiz not available');
      return;
    }

    setShowRelatedModal(true);
  };



  const handleTouchStart = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    setSelectionStart({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    const touch = e.changedTouches[0];
    setSelectionEnd({ x: touch.clientX, y: touch.clientY });
    handleCustomSelection();
  };

  const handleCustomSelection = () => {
    const selection = window.getSelection();
    const range = document.caretRangeFromPoint(selectionEnd.x, selectionEnd.y);
    if (range) {
      selection.removeAllRanges();
      selection.addRange(range);
      handleTextSelection(highlightType);
    }
  };


  const fetchQuizzes = useCallback(async () => {
    setLoading(true);
    try {
      let quizzesData = [];

      if (selectedNums && selectedNums.length > 0) {
        console.log("Attempting to fetch the following quizzes:", selectedNums);
        const queries = selectedNums.filter(item => item.num != null && item.categoryId != null).map(item =>
          query(collection(db, 'quizzes'),
            where('num', '==', item.num),
            where('category', '==', item.categoryId)
          )
        );
        const snapshots = await Promise.all(queries.map(q => getDocs(q)));
        quizzesData = snapshots.flatMap(snapshot =>
          snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        );
        console.log("Fetched quizzes:", quizzesData);
        console.log("Successfully retrieved:", quizzesData.length === selectedNums.length ? "All" : `${quizzesData.length} out of ${selectedNums.length}`);
      } else {
        let quizzesQuery = collection(db, 'quizzes');

        if (category) {
          quizzesQuery = query(quizzesQuery, where('category', '==', category));
        }
        if (subCategory) {
          quizzesQuery = query(quizzesQuery, where('subCategory', '==', subCategory));
        }
        if (subSubCategory) {
          quizzesQuery = query(quizzesQuery, where('subSubCategory', '==', subSubCategory));
        }
        if (quizCount) {
          quizzesQuery = query(quizzesQuery, limit(quizCount));
        }

        const quizzesSnapshot = await getDocs(quizzesQuery);
        quizzesData = quizzesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Fetched quizzes:", quizzesData);
        console.log("Total quizzes set:", quizzesData.length);

      }

      if (isRandom) {
        quizzesData = quizzesData.sort(() => Math.random() - 0.5);
      }

      const answersData = quizzesData.map(quiz => ({
        id: quiz.id,
        answer: String(quiz.type === 'd' && quiz.answer && quiz.answer[0] && quiz.answer[0].text ? quiz.answer[0].text : quiz.answer)
      }));

      setQuizzes(quizzesData);
      setAnswers(answersData);
      setQuizStates(quizzesData.map(() => ({ userAnswer: '', showExplanation: false, answerSubmitted: false, isCorrect: null })));
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    } finally {
      setLoading(false);
    }
  }, [category, subCategory, subSubCategory, quizCount, isRandom, selectedNums]);
  const fetchSavedStatus = useCallback(async (quizId) => {
    const user = auth.currentUser;
    if (!user) return;
    const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', quizId);
    const userQuizSnap = await getDoc(userQuizRef);
    setSavedQuizzes(prev => ({
      ...prev,
      [quizId]: userQuizSnap.exists() && userQuizSnap.data().isSaved
    }));
  }, []);

  const fetchMemo = useCallback(async (quizId) => {
    const user = auth.currentUser;
    if (!user) return;
    const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', quizId);
    const userQuizSnap = await getDoc(userQuizRef);
    if (userQuizSnap.exists() && userQuizSnap.data().memo) {
      setMemo(userQuizSnap.data().memo);
    } else {
      setMemo('');
    }
  }, []);
  const fetchHighlights = useCallback(async (quizId) => {
    const user = auth.currentUser;
    if (!user) return;
    const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', quizId);
    const userQuizSnap = await getDoc(userQuizRef);
    if (userQuizSnap.exists() && userQuizSnap.data().highlights) {
      setHighlights(userQuizSnap.data().highlights);
    } else {
      setHighlights([]);
    }
  }, []);

  const fetchColorPalette = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) return;
    const userSettingsRef = doc(db, 'users_settings', user.uid);
    const userSettingsSnap = await getDoc(userSettingsRef);
    if (userSettingsSnap.exists() && userSettingsSnap.data().colorPalette) {
      setColorPalette(userSettingsSnap.data().colorPalette);
    }
  }, []);

  useEffect(() => {
    const fetchQuizData = async () => {
      await fetchQuizzes();
      await fetchColorPalette();
      setIsLoading(false);
    };

    fetchQuizData();
  }, [fetchQuizzes, fetchColorPalette]);

  useEffect(() => {
    if (quizzes[currentQuizIndex]) {
      fetchHighlights(quizzes[currentQuizIndex].id);
    }
  }, [currentQuizIndex, fetchHighlights, quizzes]);

  const updateQuizStats = async (quizId, field, value) => {
    const user = auth.currentUser;
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', quizId);
    await runTransaction(db, async (transaction) => {
      const userQuizDoc = await transaction.get(userQuizRef);
      if (userQuizDoc.exists()) {
        const currentData = userQuizDoc.data();
        const currentValue = currentData[field];
        const newValue = value !== undefined ? value : (isNaN(currentValue) ? 1 : currentValue + 1);
        transaction.update(userQuizRef, {
          [field]: newValue,
          lastSolved: serverTimestamp()
        });
      } else {
        transaction.set(userQuizRef, {
          userId: user.uid,
          quizId,
          [field]: value !== undefined ? value : 1,
          lastSolved: serverTimestamp()
        });
      }
    });
  };


  const handleSubmit = async (answer) => {
    await updateQuizStats(currentQuiz.id, 'solvedCount');

    if (!currentQuizState.answerSubmitted) {
      const updatedQuizStates = [...quizStates];
      const correctAnswer = answers.find(a => a.id === currentQuiz.id)?.answer;

      updatedQuizStates[currentQuizIndex] = {
        ...currentQuizState,
        userAnswer: answer,
        showExplanation: true,
        answerSubmitted: true,
        isCorrect: answer === correctAnswer
      };
      setQuizStates(updatedQuizStates);
      setWarningMessage('');

      dispatch(setQuizAnswer(currentQuiz.id, answer));

      if (answer !== correctAnswer) {
        await updateQuizStats(currentQuiz.id, 'wrongCount');
      } else {
        await updateQuizStats(currentQuiz.id, 'correctCount');
      }
    }
  };

  const handleNext = () => {
    if (!currentQuizState.answerSubmitted) {
      setWarningMessage('문제를 먼저 풀어주세요.');
      return;
    }
    if (currentQuizIndex < quizzes.length - 1) {
      setCurrentQuizIndex(currentQuizIndex + 1);
      setWarningMessage('');
    } else {
      setShowAnalysis(true);
    }
  };

  const handlePrevious = () => {
    if (currentQuizIndex > 0) {
      setCurrentQuizIndex(currentQuizIndex - 1);
      setWarningMessage('');
    }
  };

  const handleDontKnow = async () => {
    const updatedQuizStates = [...quizStates];
    updatedQuizStates[currentQuizIndex] = {
      ...currentQuizState,
      showExplanation: true,
      answerSubmitted: true
    };

    setQuizStates(updatedQuizStates);
    setWarningMessage('');
    await updateQuizStats(currentQuiz.id, 'solvedCount');
    await updateQuizStats(currentQuiz.id, 'dontKnowCount');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(currentQuizState.userAnswer);
    }
  };

  const toggleSaveQuiz = async (quizId) => {
    const user = auth.currentUser;
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    const batch = writeBatch(db);
    const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', quizId);
    const userSavedRef = doc(db, 'users_saved', user.uid);

    const userQuizSnap = await getDoc(userQuizRef);
    const isCurrentlySaved = userQuizSnap.exists() && userQuizSnap.data().isSaved;

    if (userQuizSnap.exists()) {
      batch.update(userQuizRef, {
        isSaved: !isCurrentlySaved,
        question: currentQuiz.question.slice(0, 80),
        num: currentQuiz.num,
        categoryPath: currentQuiz.categoryPath
      });
    } else {
      batch.set(userQuizRef, {
        userId: user.uid,
        quizId,
        isSaved: true,
        solvedCount: 0,
        wrongCount: 0,
        dontKnowCount: 0,
        lastSolved: serverTimestamp(),
        question: currentQuiz.question.slice(0, 80),
        num: currentQuiz.num,
        categoryPath: currentQuiz.categoryPath
      });
    }

    if (isCurrentlySaved) {
      batch.update(userSavedRef, {
        quizzes: arrayRemove(quizId)
      });
    } else {
      batch.update(userSavedRef, {
        quizzes: arrayUnion(quizId)
      });
    }

    await batch.commit();

    setSavedQuizzes(prev => ({ ...prev, [quizId]: !isCurrentlySaved }));
  };


  const handleSaveMemo = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', currentQuiz.id);
    await updateDoc(userQuizRef, {
      memo,
      lastModified: serverTimestamp()
    });

    setIsEditingMemo(false);
    setCurrentSelection(null);
    setShowColorPicker(false);
  };
  useEffect(() => {
    const element = getContainerElement(highlightType);
    if (element) {
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (element) {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [highlightType]);


  useEffect(() => {
    if (quizzes[currentQuizIndex]) {
      fetchMemo(quizzes[currentQuizIndex].id);
    }
  }, [currentQuizIndex, fetchMemo, quizzes]);

  const handleTextSelection = (type, event) => {
    if (event && event.type === 'touchend') {
      event.preventDefault();
    }

    setTimeout(() => {
      setCurrentSelection(null);
      setShowColorPicker(false);

      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const containerElement = getContainerElement(type);
        const rect = range.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const containerRect = containerElement.getBoundingClientRect();

        if (!containerElement.contains(range.commonAncestorContainer)) {
          setShowColorPicker(false);
          return;
        }

        if (isNonHighlightableElementSelected(range)) {
          setShowColorPicker(false);
          return;
        }

        const selectedText = range.toString().trim();
        if (selectedText === '') {
          setShowColorPicker(false);
          return;
        }

        const fullText = containerElement.textContent;
        const absoluteStart = getAbsoluteOffset(range.startContainer, range.startOffset, containerElement, fullText);
        const absoluteEnd = getAbsoluteOffset(range.endContainer, range.endOffset, containerElement, fullText);

        if (absoluteStart < 0 || absoluteEnd < 0 || absoluteStart === absoluteEnd) {
          setShowColorPicker(false);
          return;
        }

        const isOverlapping = checkOverlappingHighlights(absoluteStart, absoluteEnd, type);

        if (!isOverlapping) {
          const rects = range.getClientRects();
          const lastRect = rects[rects.length - 1];
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          setSelectionPosition({
            x: lastRect.right,
            y: lastRect.bottom
          });
          setHighlightType(type);
          setShowColorPicker(true);
          setCurrentSelection({ start: absoluteStart, end: absoluteEnd });
        } else {
          setShowColorPicker(false);
        }
      }
    }, 10);
  };

  const isNonHighlightableElementSelected = (range) => {
    const nonHighlightableSelectors = 'h1, h2, h3, h4, h5, h6, button, .btn';
    const elements = document.querySelectorAll(nonHighlightableSelectors);

    for (let element of elements) {
      if (range.intersectsNode(element)) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      if (selection.rangeCount > 0 && selection.toString().trim() !== '') {
        const range = selection.getRangeAt(0);
        const rects = range.getClientRects();
        if (rects.length > 0) {
          const lastRect = rects[rects.length - 1];
          setSelectionPosition({ x: lastRect.right, y: lastRect.bottom });
          setShowColorPicker(true);
        } else {
          setShowColorPicker(false);
        }
      } else {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  const getContainerElement = (type) => {
    switch (type) {
      case 'question': return questionRef.current;
      case 'explanation': return explanationRef.current;
      case 'comparison': return comparisonRef.current;
      case 'memo': return memoRef.current;
      default: return null;
    }
  };

  const getAbsoluteOffset = (node, offset, container, fullText) => {
    let currentNode = container.firstChild;
    let absoluteOffset = 0;
    let found = false;

    const traverse = (n) => {
      if (found) return;
      if (n === node) {
        absoluteOffset += offset;
        found = true;
        return;
      }
      if (n.nodeType === Node.TEXT_NODE) {
        absoluteOffset += n.length;
      } else if (n.nodeType === Node.ELEMENT_NODE) {
        if (n.classList.contains('highlighted-text') || n.classList.contains('underlined-text')) {
          const nodeText = n.textContent;
          const nodeIndex = fullText.indexOf(nodeText, absoluteOffset);
          if (nodeIndex !== -1) {
            absoluteOffset = nodeIndex + nodeText.length;
          }
        } else {
          for (let child of n.childNodes) {
            traverse(child);
          }
        }
      }
    };

    while (currentNode && !found) {
      traverse(currentNode);
      currentNode = currentNode.nextSibling;
    }

    return absoluteOffset;
  };

  const checkOverlappingHighlights = (start, end, type) => {
    const highlightsArray = highlights.filter(h => h.type === type);
    return highlightsArray.some(highlight => {
      let adjustedStart = highlight.startOffset;
      let adjustedEnd = highlight.endOffset;
      if (type === 'explanation' || type === 'comparison') {
        adjustedStart += 2;
        adjustedEnd += 2;
      }
      return (start < adjustedEnd && end > adjustedStart);
    });
  };

  const applyHighlight = async (color) => {
    const user = auth.currentUser;
    if (!user || !currentSelection) return;

    let adjustedStart = currentSelection.start;
    let adjustedEnd = currentSelection.end;

    if (highlightType === 'explanation' || highlightType === 'comparison') {
      adjustedStart -= 2;
      adjustedEnd -= 2;
    }

    if (adjustedStart < 0 || adjustedEnd < 0) {
      setShowColorPicker(false);
      return;
    }

    const newHighlight = {
      id: Date.now().toString(),
      text: getContainerElement(highlightType).textContent.slice(adjustedStart, adjustedEnd),
      color: color,
      startOffset: adjustedStart,
      endOffset: adjustedEnd,
      type: highlightType
    };

    try {
      const userQuizRef = doc(db, 'userQuizStats', user.uid, 'quizzes', currentQuiz.id);
      const userQuizSnap = await getDoc(userQuizRef);

      let updatedHighlights = [];
      if (userQuizSnap.exists() && userQuizSnap.data().highlights) {
        updatedHighlights = [...userQuizSnap.data().highlights, newHighlight];
      } else {
        updatedHighlights = [newHighlight];
      }

      await setDoc(userQuizRef, {
        userId: user.uid,
        quizId: currentQuiz.id,
        highlights: updatedHighlights,
        lastModified: serverTimestamp()
      }, { merge: true });

      setHighlights(updatedHighlights);
    } catch (error) {
      console.error('Error saving highlight:', error);
    }
    setShowColorPicker(false);
  };

  const removeHighlight = async (highlightId) => {
    const updatedHighlights = highlights.filter(h => h.id !== highlightId);

    try {
      const userQuizRef = doc(db, 'userQuizStats', auth.currentUser.uid, 'quizzes', currentQuiz.id);
      await updateDoc(userQuizRef, { highlights: updatedHighlights });
      setHighlights(updatedHighlights);
    } catch (error) {
      console.error('Error removing highlight:', error);
    }
  };

  const renderTextWithStyles = (text, type) => {
    if (!text) return text;

    const highlightsArray = highlights.filter(h => h.type === type && h.startOffset >= 0 && h.endOffset >= 0);
    const underlinesArray = currentQuiz?.underlineSelections?.[type] || [];

    const styleMap = new Array(text.length).fill().map(() => ({ isHighlight: false, isUnderline: false, color: null, highlightId: null }));

    highlightsArray.forEach(h => {
      for (let i = h.startOffset; i < h.endOffset; i++) {
        if (i < styleMap.length) {
          styleMap[i].isHighlight = true;
          styleMap[i].color = h.color;
          styleMap[i].highlightId = h.id;
        }
      }
    });

    underlinesArray.forEach(u => {
      for (let i = u.start; i < u.end; i++) {
        if (i < styleMap.length) {
          styleMap[i].isUnderline = true;
        }
      }
    });

    let result = [];
    let currentStyle = null;
    let currentText = '';
    let startIndex = 0;

    for (let i = 0; i < text.length; i++) {
      const style = styleMap[i];
      if (JSON.stringify(style) !== JSON.stringify(currentStyle)) {
        if (currentText) {
          result.push(renderStyledSpan(currentText, currentStyle, type, startIndex));
          startIndex = i;
          currentText = '';
        }
        currentStyle = style;
      }
      currentText += text[i];
    }

    if (currentText) {
      result.push(renderStyledSpan(currentText, currentStyle, type, startIndex));
    }

    return (
      <div
        onTouchEnd={() => handleTextSelection(type)}
        onMouseUp={() => handleTextSelection(type)}
      >
        {result}
      </div>
    );
  };


  useEffect(() => {
    const loadQuizAndSavedStatus = async () => {
      if (quizzes[currentQuizIndex]) {
        const currentQuiz = quizzes[currentQuizIndex];
        await fetchHighlights(currentQuiz.id);
        await fetchSavedStatus(currentQuiz.id);
      }
    };

    loadQuizAndSavedStatus();
  }, [currentQuizIndex, fetchHighlights, fetchSavedStatus, quizzes]);

  const renderStyledSpan = (text, style, type, startIndex) => {
    const spanStyle = {
      backgroundColor: style.isHighlight ? style.color : undefined,
      textDecoration: style.isUnderline ? 'underline' : 'none',
      textDecorationColor: style.isUnderline ? '#00000060' : undefined,
      textDecorationThickness: style.isUnderline ? '1.5px' : undefined,
      textUnderlineOffset: style.isUnderline ? '1px' : undefined,
      cursor: style.isHighlight ? 'pointer' : 'default',
    };

    return (
      <span
        key={`styled-${type}-${startIndex}-${text}`}
        style={spanStyle}
        onClick={() => style.isHighlight && removeHighlight(style.highlightId)}
      >
        {text}
      </span>
    );
  };

  const renderQuestion = () => {
    if (!currentQuiz) return null;

    if (currentQuiz.type === 'd') {
      const { question, answer } = currentQuiz;
      const { start, end, text } = answer[0];
      const blank = '_'.repeat(end - start);
      const displayedQuestion = question.slice(0, start) + blank + question.slice(end);
      return currentQuizState.answerSubmitted ? (
        <span>
          {question.slice(0, start)}
          <span className="filled-blank">{text}</span>
          {question.slice(end)}
        </span>
      ) : (
        displayedQuestion
      );
    }

    return renderTextWithStyles(currentQuiz.question.replace(/_/g, '____'), 'question');
  };

  const renderExplanation = () => {
    if (!currentQuizState.showExplanation) return null;

    return (
      <div className="explanation" ref={explanationRef} onMouseUp={() => handleTextSelection('explanation')}>
        <p>해설</p>
        <p>{renderTextWithStyles(currentQuiz.explanation, 'explanation')}</p>
      </div>
    );
  };

  const renderComparison = () => {
    if (!currentQuizState.showExplanation || !currentQuiz.comparison) return null;

    return (
      <div className="explanation" ref={comparisonRef} onMouseUp={() => handleTextSelection('comparison')}>
        <p>비교</p>
        <p>{renderTextWithStyles(currentQuiz.comparison, 'comparison')}</p>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (showAnalysis) {
    return <QuizResultAnalysis quizStates={quizStates} quizzes={quizzes} answers={answers} savedQuizzes={savedQuizzes} />;
  }

  return (
    <div className="quiz-solving">
      <div className="quiz-header">
        {currentQuiz && (
          <>
            <span
              className={`star-icon fas ${savedQuizzes[currentQuiz.id] ? 'fa-star saved' : 'fa-star'}`}
              onClick={() => toggleSaveQuiz(currentQuiz.id)}
            ></span>
            <span className="quiz-num"># {currentQuiz.num.toString().padStart(3, '0')}</span>
            <span className="quiz-tags">{currentQuiz.tag}</span>
            <span className="category-name">{currentQuiz.categoryPath}</span>
          </>
        )}
      </div>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${((currentQuizIndex + 1) / quizzes.length) * 100}%` }} />
      </div>
      {currentQuiz && currentQuiz.type === 'b' && currentQuiz.premise && (
        <div className="premise-container">
          <p>{currentQuiz.premise}</p>
        </div>
      )}
      <p className="question" ref={questionRef} onMouseUp={() => handleTextSelection('question')}>
        {renderQuestion()}
      </p>
      {currentQuiz && (currentQuiz.type === 'a' || currentQuiz.type === 'b') && (
        <div className="true-false-buttons">
          <button
            className={`true-false-button ${currentQuizState.userAnswer === 'true' ? 'selected' : ''} ${currentQuizState.showExplanation && currentQuiz.answer === true ? 'correct' : ''}`}
            onClick={() => handleSubmit('true')}
            disabled={currentQuizState.answerSubmitted}
          >
            O
          </button>
          <button
            className={`true-false-button ${currentQuizState.userAnswer === 'false' ? 'selected' : ''} ${currentQuizState.showExplanation && currentQuiz.answer === false ? 'correct' : ''}`}
            onClick={() => handleSubmit('false')}
            disabled={currentQuizState.answerSubmitted}
          >
            X
          </button>
        </div>
      )}
      {currentQuiz && currentQuiz.type === 'c' && (
        <div className="multiple-choice-buttons">
          {currentQuiz.options.map((option, index) => (
            <button
              key={index}
              className={`multiple-choice-button ${currentQuizState.userAnswer === (index + 1).toString() ? 'selected' : ''} ${currentQuizState.showExplanation && (index + 1).toString() === currentQuiz.answer ? 'correct' : ''}`}
              onClick={() => handleSubmit((index + 1).toString())}
              disabled={currentQuizState.answerSubmitted}
            >
              {option}
            </button>
          ))}
        </div>
      )}
      {currentQuiz && currentQuiz.type !== 'a' && currentQuiz.type !== 'b' && currentQuiz.type !== 'c' && (
        <div className="fill-in-the-blank">
          <input
            type="text"
            value={currentQuizState.userAnswer}
            onChange={(e) => {
              const updatedQuizStates = [...quizStates];
              updatedQuizStates[currentQuizIndex] = {
                ...currentQuizState,
                userAnswer: e.target.value
              };
              setQuizStates(updatedQuizStates);
            }}
            onKeyPress={handleKeyPress}
            disabled={currentQuizState.answerSubmitted}
            style={{
              backgroundColor: currentQuizState.isCorrect === null ? 'white' : currentQuizState.isCorrect ? 'lightgreen' : 'lightcoral'
            }}
          />
          <button onClick={() => handleSubmit(currentQuizState.userAnswer)} disabled={currentQuizState.answerSubmitted}>제출</button>
        </div>
      )}
{showRelatedModal && relatedQuizzes.length > 0 && (
  <div className="modal">
    <div className="modal-content">
      <div className="quiz-content">
        <h3>유사 문제     <span className="similar-rank"> ({currentRelatedIndex + 1}번째 유사 문제)</span></h3>
        <span className="close" onClick={() => setShowRelatedModal(false)}>&times;</span>
        <div className="quiz-header">
          <span className="quiz-tag">{relatedQuizzes[currentRelatedIndex].tag}</span>
          <span className="quiz-category">{relatedQuizzes[currentRelatedIndex].categoryPath}</span>
        </div>
        <p className="question_modal"><strong>문제:</strong> {relatedQuizzes[currentRelatedIndex].question}</p>
        {relatedQuizzes[currentRelatedIndex].premise && <p><strong>전제:</strong> {relatedQuizzes[currentRelatedIndex].premise}</p>}
        {relatedQuizzes[currentRelatedIndex].type === 'c' && (
          <div>
            <strong>보기:</strong>
            <ol>
              {relatedQuizzes[currentRelatedIndex].options.map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ol>
          </div>
        )}
        <p><strong>정답:</strong> {String(relatedQuizzes[currentRelatedIndex].answer)}</p>
        <p><strong>설명:</strong> {String(relatedQuizzes[currentRelatedIndex].explanation)}</p>
        {relatedQuizzes[currentRelatedIndex].comparison && <p><strong>비교:</strong> {relatedQuizzes[currentRelatedIndex].comparison}</p>}
        <div className="navigation-buttons">
          <button onClick={handlePrevRelated} disabled={currentRelatedIndex === 0}>이전</button>
          <button onClick={handleNextRelated} disabled={currentRelatedIndex === relatedQuizzes.length - 1}>다음</button>
        </div>
      </div>
    </div>
  </div>
)}


      {currentQuizState.showExplanation && (
        <div className="additional-content">
          {renderExplanation()}
          {renderComparison()}
          <div className="explanation memo-section">
            <p>메모</p>
            {isEditingMemo ? (
              <div>
                <textarea
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                  rows="4"
                  cols="50"
                />
                <button onClick={handleSaveMemo}>저장</button>
                <button onClick={() => setIsEditingMemo(false)}>취소</button>
              </div>
            ) : (
              <div>
                {memo ? (
                  <div>
                    <p ref={memoRef} onMouseUp={() => handleTextSelection('memo')}>
                      {renderTextWithStyles(memo, 'memo')}
                    </p>
                    <button onClick={() => setIsEditingMemo(true)}>메모 수정</button>
                  </div>
                ) : (
                  <button onClick={() => setIsEditingMemo(true)}>메모 추가</button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {warningMessage && <p className="warning-message">{warningMessage}</p>}
      <div className="buttons">
        <button className="btn-unknown" onClick={handleFindRelated}>유사문제</button>

        <button className="btn-unknown" onClick={handleDontKnow} disabled={currentQuizState.answerSubmitted}>모름</button>
        {currentQuizIndex > 0 && (
          <button className="btn-prev" onClick={handlePrevious} disabled={currentQuizIndex === 0}>이전으로</button>
        )}
        {fromSavedQuizzes ? (
          <button className="btn-back" onClick={() => navigate(-1)}>뒤로가기</button>
        ) : (
          <button className="btn-next" onClick={handleNext} disabled={currentQuizIndex === quizzes.length - 1 && !currentQuizState.answerSubmitted}>
            {currentQuizIndex === quizzes.length - 1 ? '종료하기' : '다음으로'}
          </button>
        )}
      </div>
      {showColorPicker && (
        <div
          ref={colorPickerRef}
          style={{
            position: 'fixed',
            left: `${selectionPosition.x}px`,
            top: `${selectionPosition.y}px`,
            zIndex: 1000,
          }}
        >
          <ColorPicker onColorSelect={applyHighlight} userId={auth.currentUser.uid} />
        </div>
      )}
    </div>
  );
};

export default QuizSolving;
